<template>
  <v-container fill-height class="d-flex align-start">
    <v-row>
      <v-col class="d-flex align-center ma-0 pa-0">
        <p class="mt-1 mr-2 section-title mb-1">Empty List Message</p>
        <v-btn
          icon
          color="caribbeanDark"
          @click="copyToClipboard('Empty List Message')"
        >
          <v-icon>mdi-code-tags</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <!-- Message Component -->
    <EmptyListMessage />
    <!-- Message Component -->
  </v-container>
</template>

<script>
import EmptyListMessage from '@/components/shared/customComponents/emptyStates/empty-list-message.vue';

export default {
  name: 'EmptyStatesWiki',
  components: {
    EmptyListMessage,
  },

  methods: {
    triggerToast(name) {
      const toastParams = {
        position: { top: true, right: true },
        timeout: 3000,
        messages: { success: `${name} copied to clipboard` },
        type: 'success',
      };

      this.$store.dispatch(
        'toastNotification/setupToastNotification',
        toastParams
      );
      this.$store.dispatch('toastNotification/showToastNotification', true);
    },

    copyToClipboard(name) {
      let component = `<EmptyListMessage />`;
      navigator.clipboard.writeText(component);
      this.triggerToast(name);
    },
  },
};
</script>

<style></style>
